<template>
  <div class="popup-detail">
    <el-dialog
      :title="(todo == 'add' ? '【新增】' : (todo == 'edit' ? '【修改】' : '【查看】')) + '收入/支出 记账本'"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="收支类型" prop="billType">
              <el-select class="item-choose" :disabled="isViews" v-model="dataForm.billType"
                         style="width: 100%;" filterable clearable
                         placeholder="请选择">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="收支日期" prop="billTime">
              <el-date-picker
                style="width: 100%;"
                :disabled="isViews"
                v-model="dataForm.billTime"
                type="datetime"
                default-time="12:00:00"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="费用(元)" prop="price">
              <el-input v-model="dataForm.price" placeholder="费用(元)" maxlength="10" show-word-limit
                        :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="凭证号" prop="proofNo">
              <el-input v-model="dataForm.proofNo" placeholder="凭证号" show-word-limit
                        :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="摘要" prop="summary">
              <el-input type="textarea" :rows="2" v-model="dataForm.summary" placeholder="摘要"
                        maxlength="1000" show-word-limit
                        :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="费用备注" prop="remark">
              <el-input type="textarea" :rows="2" v-model="dataForm.remark" placeholder="费用备注"
                        maxlength="1000" show-word-limit
                        :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="附件">
              <al-upload :fileData="dataForm.enclosureInfoList"
                         :fileUrl="$http.adornUrl('/upload/file')"
                         :isView="isViews"
                         :limit="99"
                         :businessType="fileType"
                         @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="收/付款状态" prop="writeStatus">
              <el-select class="item-choose" :disabled="isViews" v-model="dataForm.writeStatus"
                         style="width: 100%;" filterable clearable
                         placeholder="请选择">
                <el-option
                  v-for="item in dataForm.billType === 2 ?  payFeeList : paymentFeeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收/付款人" prop="writeName">
              <el-input v-model="dataForm.writeName" placeholder="收/付款人" show-word-limit
                        :disabled="isViews"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        enclosureInfoList: [],
        billType: null,
        writeStatus: null,
        writeName: null,
        billTime: null,
        price: null,
        proofNo: null,
        remark: null,
        summary: null
      },
      todo: 'view',
      fileType: 8001,
      typeList: [
        {
          label: '收入',
          value: 1
        },
        {
          label: '支出',
          value: 2
        }
      ],
      dataRule: {
        billType: [
          { required: true, message: '请选择收支类型', trigger: 'change' }
        ],
        billTime: [
          { required: true, message: '请选择收支日期', trigger: 'change' }
        ],
        summaryTime: [
          { required: true, message: '请选择年月', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入收支金额', trigger: 'blur' }
        ],
        writeStatus: [
          { required: true, message: '请选择收/付款状态', trigger: 'change' }
        ]
      },
      payFeeList: [
        {
          label: '已付款',
          value: 1
        },
        {
          label: '未付款',
          value: 2
        }
      ],
      paymentFeeList: [
        {
          label: '已收款',
          value: 3
        },
        {
          label: '未收款',
          value: 4
        }
      ]
    }
  },
  computed: {
    isViews () {
      return this.todo === 'view'
    }
  },
  methods: {
    init (todo, id) {
      this.visible = true
      this.$nextTick(() => {
        this.dataForm.enclosureInfoList = []
        this.$refs['dataForm'].resetFields()
      })
      this.dataForm.id = id
      this.todo = todo
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/financeBill/detail/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.billType = data.billType
            this.dataForm.billTime = data.billTime
            this.dataForm.price = data.price
            this.dataForm.proofNo = data.proofNo
            this.dataForm.summary = data.summary
            this.dataForm.remark = data.remark
            this.dataForm.enclosureInfoList = data.enclosureInfoList
            this.dataForm.writeStatus = data.writeStatus
            this.dataForm.writeName = data.writeName
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/financeBill/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'billType': this.dataForm.billType,
              'billTime': this.dataForm.billTime,
              'price': this.dataForm.price,
              'proofNo': this.dataForm.proofNo,
              'summary': this.dataForm.summary,
              'remark': this.dataForm.remark,
              'enclosureInfoList': this.dataForm.enclosureInfoList,
              'writeStatus': this.dataForm.writeStatus,
              'writeName': this.dataForm.writeName
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    // 获取附件信息
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    }
  }
}
</script>
